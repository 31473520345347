import React, { Component } from "react";
import AppContext from "../../appcontext";

import numeral from "numeral";
import moment from "moment";

import { Popover } from "react-bootstrap";

import { Trans } from "react-i18next";

import "./pageheader.css";

class PageHeader extends Component {
  static contextType = AppContext;

  popoverContact = (
    <Popover
      id="pageheader-popover-contact"
      title={this.context.i18n.t("Contact Info")}
    >
      <p>
        <span className="pageheader-popover-contact-header">
          <Trans>Email</Trans>:
        </span>{" "}
        {this.context.advisor_info.email ||
        this.context.advisor_info.team_email ? (
          <span className="pageheader-popover-contact-value">
            <a
              href={
                "mailto:" +
                (this.context.advisor_info.team_email
                  ? this.context.advisor_info.team_email
                  : this.context.advisor_info.email)
              }
            >
              {this.context.advisor_info.team_email
                ? this.context.advisor_info.team_email
                : this.context.advisor_info.email}
            </a>
          </span>
        ) : (
          <span className="pageheader-popover-contect-notset">--</span>
        )}
      </p>
      {
        //this.context.advisor_info.phone_number ? <p><span className="pageheader-popover-contact-header"><Trans>Phone</Trans>:</span> <span className="pageheader-popover-contact-value">{formatPhoneNumberIntl(this.context.advisor_info.phone_number)}</span></p> : false
      }
    </Popover>
  );

  render() {
    return (
      <div className="pageheader-container">
        <div className="pageheader-grid">
          <div className="pageheader-welcome">
            <div className="pageheader-welcome-message">
              {this.context.getGreeting()}
              {this.context.user_info.first_name !== undefined
                ? this.context.user_info.first_name !== null ? " " + this.context.user_info.first_name : "" 
                : false}
              {this.context.user_info.last_name !== undefined
                ? this.context.user_info.last_name !== null ? " " + this.context.user_info.last_name : "" 
                : false}
            </div>
            <div className="pageheader-welcome-notification">
              {this.context.document_counts !== undefined &&
              this.context.document_counts.unread !== undefined &&
              this.context.document_counts.unread > 0 ? (
                <span onClick={() => this.context.navigate("/documents")}>
                  {this.context.i18n.t(
                    "You have {{docs}} new documents to consult",
                    { docs: this.context.document_counts.unread }
                  )}
                </span>
              ) : (
                false
              )}
            </div>
          </div>
          <div className="pageheader-balances">
            <div className="pageheader-balance-item">
              <div className="pageheader-balance-item-header">
                <Trans>MANAGED ASSETS</Trans>
              </div>
              <div className="pageheader-balance-item-value">
                {this.context.header_balances !== undefined &&
                this.context.header_balances.managed !== undefined ? (
                  <span>
                    {numeral(this.context.header_balances.managed).format(
                      "$ 0,0"
                    )}
                  </span>
                ) : (
                  "--"
                )}
              </div>
            </div>
            <div className="pageheader-balance-symbol">+</div>
            <div className="pageheader-balance-item">
              <div className="pageheader-balance-item-header">
                <Trans>OTHER ASSETS</Trans>
              </div>
              <div className="pageheader-balance-item-value">
                {this.context.header_balances !== undefined &&
                this.context.header_balances.nonmanaged !== undefined ? (
                  <span>
                    {numeral(this.context.header_balances.nonmanaged).format(
                      "$ 0,0"
                    )}
                  </span>
                ) : (
                  "--"
                )}
              </div>
            </div>
            <div className="pageheader-balance-symbol">=</div>
            <div className="pageheader-balance-item">
              <div className="pageheader-balance-item-header">
                <Trans>YOUR TOTAL PORTFOLIO</Trans>
              </div>
              <div className="pageheader-balance-item-value">
                {this.context.header_balances !== undefined &&
                this.context.header_balances.total !== undefined ? (
                  <span className="pageheader-balance-item-value-highlight">
                    {numeral(this.context.header_balances.total).format(
                      "$ 0,0"
                    )}
                  </span>
                ) : (
                  "--"
                )}
              </div>
            </div>
            {/* <div className="pageheader-balance-item fee-pct-item">
                        <div className="pageheader-balance-item-header"><Trans>FEES</Trans></div>
                        <div className="pageheader-balance-item-value">{this.context.header_balances !== undefined && this.context.header_balances.fee_ebps !== undefined? (<span>{numeral(this.context.header_balances.fee_ebps/10000).format('0.00 %')}</span>) : '--'}</div>
                    </div> */}
            {this.context.header_balances !== undefined &&
            this.context.header_balances.date !== undefined ? (
              <div className="pageheader-balance-asof">
                <Trans>as of</Trans>{" "}
                {moment(this.context.header_balances.date, "YYYY-MM-DD").format(
                  "LL"
                )}
              </div>
            ) : (
              false
            )}
          </div>
          <div className="pageheader-advisor">
            {/* <div className="pageheader-advisor-fill">&nbsp;</div>
                    <div className="pageheader-advisor-info">
                        <div className="pageheader-advisor-info-image">{this.context.advisor_info.photo === null ? <FontAwesomeIcon icon={["fas", "user-circle"]} /> : <img className="pageheader-advisor-info-photo" alt="" src={this.context.advisor_info.photo}/>}</div>
                        <div className="pageheader-advisor-info-name">{this.context.advisor_info.first_name} {this.context.advisor_info.last_name}</div>
                        <div className="pageheader-advisor-info-contact">
                            <OverlayTrigger
                                trigger="click"
                                rootClose
                                placement="bottom"
                                overlay={this.popoverContact}>
                                <span><Trans>Contact Me</Trans></span>
                            </OverlayTrigger>
                        </div>
                    </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default PageHeader;
